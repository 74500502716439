<template>

    <!-- Footer -->
    <footer class="main-footer text-white bg-rotate position-relative">
        <div class="top-footer">
            <div class="container">
				<div class="row">
                    <div class="col-lg-4 col-sm-4">
                        <div class="footer-widget">
                            <h3 class="widget-title" data-animate="fadeInUp" data-delay=".0">什么是 GeckoRelay？</h3>
                            <div class="footer-posts">
                                <ul class="list-unstyled">
                                    <li data-animate="fadeInUp" data-delay=".05">
                                        <p>简单来说，它是一个代理方案，可以用来学习国外先进科学技术。目前我们在欧洲，北美洲以及亚洲多个国家均部署有接入节点，所有接入点全部使用冗余架构。</p>
                                    </li>
                                    <li data-animate="fadeInUp" data-delay=".15">
                                        <p>每个接入地址后面对应N个负载均衡器以及数量至少为2N的出口节点，确保最大化的系统可用性。</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="footer-widget">
                            <h3 class="widget-title" data-animate="fadeInUp" data-delay=".0">GeckoRelay安全吗？</h3>
                            <div class="footer-posts">
                                <ul class="list-unstyled">
                                    <li data-animate="fadeInUp" data-delay=".05">
                                        <p>接入集群之间全部采用加密通讯，同时客户的注册资料在系统中也是采用加密储存，最小化被黑客攻击的可能性。</p>
                                    </li>
                                    <li data-animate="fadeInUp" data-delay=".15">
                                        <p>团队高防版在提供一切团队版所含的功能时额外增加了中国大陆地区的中转节点以及高达100Gbps DDoS攻击防御服务，确保您的网络接入绝对稳定。</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="footer-widget">
                            <h3 class="widget-title" data-animate="fadeInUp" data-delay=".0">企业版的特色是什么？</h3>
                            <div class="footer-posts">
                                <ul class="list-unstyled">
                                    <li data-animate="fadeInUp" data-delay=".05">
                                        <p>企业版提供无限流量的接入服务，接入点也均为独立构建，稳定性，安全性均比非企业版更上一层楼，同时企业版也享受更为优秀的客户服务体验，解决使用中的问题。</p>
                                    </li>
                                    <li data-animate="fadeInUp" data-delay=".15">
                                        <p>非企业版传输量耗尽后，可以在用户面板中为账户购买额外的传输量资源包，单月购买的流量无上限，每个自然月会自动清空。</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>

<hr style="border-top: 1px solid rgba(255,255,255,0.1);" />

        <div class="o-container u-textAlign-center u-padTop-x8 u-padBottom-x8">
            <div class="o-grid o-grid--withGutter o-grid--alignCenter">
                <div class="o-grid__cell u-cellSize-8of12@lgo">
                    <h1 class="o-heading u-fontWeight-bold u-marBottom-x2 u-color-white">使用 GeckoRelay 服务，开启新生活</h1>
                    <p class="uu-marBottom-x4 u-color-white">立即开始使用 GeckoRelay 数据传输服务，享受精彩网络体验</p>
                    <div class="c-buttonsGroup">
                        <!--a href="https://geckorelay.com/register.php" class="o-button o-button--outlineWhite c-buttonsGroup__btn">免费注册</a-->
                        <a href="/pricing" class="o-button o-button--outlineWhite c-buttonsGroup__btn">查看套餐</a>
                    </div>
                </div>
            </div>
        </div>

		<!-- Bottom footer -->
		<div class="bottom-footer position-relative">
			<div class="container">
				<div class="row align-items-center">
                    <!-- Copyright -->
                    <div class="col-md-5 order-last order-md-first">
                        <span class="copyright-text" data-animate="fadeInDown" data-delay=".7">Copyright &copy; 2018-2020 <a href="/">GeckoRelay</a>. All Rights Reserved.</span>
                    </div>
                    <!-- End of Copyright -->

                    <!-- Footer menu -->
                    <div class="col-md-7 order-first order-md-last">
                        <ul class="footer-menu text-md-right list-inline">
                            <li data-animate="fadeInDown" data-delay=".75"><a href="/pricing">服务套餐</a></li>
                            <li data-animate="fadeInDown" data-delay=".8"><a target="_blank" href="//doc.geckorelay.me">帮助文档</a></li>
                            <!--li data-animate="fadeInDown" data-delay=".85"><a href="//geckorelay.com/affiliates.php">推广联盟</a></li-->
                            <!--li data-animate="fadeInDown" data-delay=".9"><a href="/legal">服务条款</a></li-->
                        </ul>
                    </div>
                    <!-- End of Footer menu -->
                </div>
			</div>

            <!-- Back to top -->
            <a class="back-to-top" href="#" data-animate="fadeInDown" data-delay=".95">
                <i class="fas fa-arrow-up"></i>
            </a>
            <!-- End of Back to top -->
		</div>
		<!-- End of Bottom footer -->
    </footer>
    <!-- End of Footer -->
</template>

<script>
export default {
  name: 'MainFooter',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
