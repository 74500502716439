<template>
    <!-- Banner -->
    <section class="main-banner bg-primary bg-rotate position-relative">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <!-- Banner content -->
                    <div class="banner-content text-center text-white">
                        <h2 data-animate="fadeInUp" data-delay="1.2">GeckoRelay. <br>New Space for World</h2>
                        <p data-animate="fadeInUp" data-delay="1.3">全新VMESS协议 带来高速稳定的网络体验<br>
                            全球10+高速节点，5+动态节点，助您加速网络，即刻选购开启人生。</p>
                        <ul class="list-inline" data-animate="fadeInUp" data-delay="1.4">
                            <li><a class="btn btn-transparent" href="/pricing">产品价格</a></li>
                            <!--li><a class="btn btn-transparent" href="//geckorelay.com/cart.php">立即购买</a></!li-->
                        </ul>
                    </div>
                    <!-- End of Banner content -->
                </div>
            </div>
            <span class="goDown" data-animate="fadeInUp" data-delay="1.5"><i class="fas fa-arrow-down bounce"></i></span>
        </div>
    </section>
    <!-- End of Banner -->
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
