











import Vue from 'vue';
import MainHeader from '@/components/MainHeader.vue'
import Banner from '@/components/Banner.vue'
import Features from '@/components/Features.vue'
//import Pricing from '@/components/Pricing.vue'
import Reviews from '@/components/Reviews.vue'
import MainFooter from '@/components/MainFooter.vue'

export default Vue.extend({
  name: 'home',
  components: {
    MainHeader,
    Banner,
    Features,
//    Pricing,
    Reviews,
    MainFooter,
//    HelloWorld,
  },
});
