<template>
    <!-- Home features -->
    <section class="home-features pt-175 pb-175">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-home-feature" data-animate="fadeInUp" data-delay=".1">
                        <img class="svg" src="img/security.svg" alt="">
                        <h3>数据安全优先</h3>
                        <p>严格保护用户隐私。所有流量数据流和客户端帐户信息都被严格加密。</p>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-home-feature" data-animate="fadeInUp" data-delay=".3">
                        <img class="svg" src="img/high.svg" alt="">
                        <h3>高速冲浪体验</h3>
                        <p>针对国内网络优化的全球极速CN2 GIA，让您的网络如虎添翼。</p>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-home-feature" data-animate="fadeInUp" data-delay=".5">
                        <img class="svg" src="img/guard.svg" alt="">
                        <h3>使用方便快捷</h3>
                        <p>一键下单，自动开通。我们有专业的客服团队在最短的时间内回答您的疑问。</p>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-home-feature" data-animate="fadeInUp" data-delay=".7">
                        <img class="svg" src="img/support.svg" alt="">
                        <h3>不断创新进步</h3>
                        <p>更快，更方便，更好看是我们的目标。您将与我们一起体验技术创新的优化。</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End of Home features -->
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
