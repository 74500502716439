<template>
    <!-- Reviews -->
    <section class="bg-rotate position-relative pt-175">
        <div class="container">
            <!-- Section title -->
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-8">
                    <div class="section-title text-center">
                        <h2 data-animate="fadeInUp" data-delay=".1">GeckoRelay 用户说</h2>
                        <p data-animate="fadeInUp" data-delay=".2">适合于任何需要加速的网络使用环境</p>
                    </div>
                </div>
            </div>
            <!-- End of Section title -->

            <!-- Review slider -->
            <div class="review-slider-wrap">
                <div class="swiper-container review-slider">
                    <div class="swiper-wrapper">
                        <!-- Single review -->
                        <div class="swiper-slide bg-dark bg-rotate single-review">
                            <div class="review-info">
                                <i class="fas fa-quote-left float-left"></i>
                                <h3>设计师</h3>
                                <span>Designer</span>
                            </div>
                            <p>我需要经常关注欧洲、美国和日本的平面设计趋势。我喜欢平面和原创的设计。我Pinterest的收藏就像一个博物馆。</p>
                        </div>
                        <!-- End of Single review -->

                        <!-- Single review -->
                        <div class="swiper-slide bg-dark bg-rotate single-review">
                            <div class="review-info">
                                <i class="fas fa-quote-left float-left"></i>
                                <h3>技术人员</h3>
                                <span>Technologists</span>
                            </div>
                            <p>我关心苹果和谷歌的最新技术。我能够在YouTube上寻找各种各样的开箱和拆解视频，4K和8K高清视频体验是我最大的兴趣。</p>
                        </div>
                        <!-- End of Single review -->

                        <!-- Single review -->
                        <div class="swiper-slide bg-dark bg-rotate single-review">
                            <div class="review-info">
                                <i class="fas fa-quote-left float-left"></i>
                                <h3>外企员工</h3>
                                <span>Workers in Foreign Companies</span>
                            </div>
                            <p>我是TVB，HBO和Netflix的粉丝。我正在追“奇怪的故事”和“纸牌之家”这两个剧。观看预告片也是我的最爱。</p>
                        </div>
                        <!-- End of Single review -->

                        <!-- Single review -->
                        <div class="swiper-slide bg-dark bg-rotate single-review">
                            <div class="review-info">
                                <i class="fas fa-quote-left float-left"></i>
                                <h3>国际留学生</h3>
                                <span>International Students</span>
                            </div>
                            <p>每个冬季和暑假我都必须回家和我的家人团聚。我还需要维护我的Facebook和Twitter。他们是我的秘密花园。</p>
                        </div>
                        <!-- End of Single review -->

                        <!-- Single review -->
                        <div class="swiper-slide bg-dark bg-rotate single-review">
                            <div class="review-info">
                                <i class="fas fa-quote-left float-left"></i>
                                <h3>时尚人士</h3>
                                <span>Fashion People</span>
                            </div>
                            <p>我每天都在泡在Instagram和Pinterest上，因为我需要不断关注欧美时尚博客的动态分享。我想追逐最前卫的走秀和美妆。</p>
                        </div>
                        <!-- End of Single review -->
                    </div>
                </div>
            </div>
            <!-- End of Review slider -->

            <!-- Review slider pagination -->
            <div class="swiper-pagination review-pagination"></div>
        </div>
    </section>
    <!-- End of Reviews -->
</template>

<script>
export default {
  name: 'Reviews',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
