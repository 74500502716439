<template>
    <!-- Main header -->
    <header class="main-header bg-primary">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-8">
                    <!-- Logo -->
                    <div class="logo" data-animate="fadeInDown" data-delay=".5">
                        <a href="//geckorelay.me">
                            <img data-rjs="3" src="/img/gecko-white.png" alt="GeckoRelay">
                            <img data-rjs="3" src="/img/gecko-blue.png" alt="GeckoRelay">
                        </a>
                    </div>
                    <!-- End of Logo -->
                </div>
                <div class="col-xl-7 col-lg-7 col-md-5 col-sm-2 col-4">
                    <nav data-animate="fadeInDown" data-delay=".75">
                        <!-- Header-menu -->
                        <div class='header-menu'>
                            <ul>
                                <li><a href="/pricing">产品价格</a></li>
                                <li>
                                    <a href="/integrations">兼容软件 <i class="fas fa-caret-down" /></a>
                                    <ul>
                                        <li><a href="/integrations">兼容软件</a></li>
                                        <li><a href="//github.com/GeckoRelay/geckorelay-client/releases" target="_blank">终端下载 <i class="fas fa-link" /></a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href='/how-it-works'>资源支持 <i class="fas fa-caret-down" /></a>
                                    <ul>
                                        <li><a href="/how-it-works">工作原理</a></li>
                                        <li><a href="//doc.geckorelay.me" target="_blank">教程文档 <i class="fas fa-link" /></a></li>
                                    </ul>
                                </li>
                                <!--li class="active"><a href='//geckorelay.me'>首页</a></li>
                                <li><a href='/about'>About</a></li>
                                <li>
                                    <a href='/relay'>产品 <i class="fas fa-caret-down" /></a>
                                    <ul>
                                        <li><a href="/relay">中继服务</a></li>
                                        <li><a href="/express">企业订制</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href='/pricing'>价格&优惠 <i class="fas fa-caret-down" /></a>
                                    <ul>
                                        <li><a href="/pricing">价格</a></li>
                                        <li><a href="/education">教育优惠</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href='/how-it-works'>资源支持 <i class="fas fa-caret-down" /></a>
                                    <ul>
                                        <li><a href="/how-it-works">工作原理</a></li>
                                        <li><a href="/integrations">兼容软件</a></li>
                                        <li><a href="//github.com/GeckoRelay/geckorelay-client/releases" target="_blank">终端下载 <i class="fas fa-link" /></a></li>
                                        <li><a href="//doc.geckorelay.me" target="_blank">教程文档 <i class="fas fa-link" /></a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href='/legal'>条款&保障 <i class="fas fa-caret-down" /></a>
                                    <ul>
                                        <li><a href="/legal/tos">服务条款</a></li>
                                        <li><a href="/legal/privacy">隐私政策</a></li>
                                        <li><a href="/legal/policy">使用政策</a></li>
                                    </ul>
                                </li-->
                            </ul>
                        </div>
                        <!-- End of Header-menu -->
                    </nav>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-none d-sm-block">
                    <!-- Register button -->
                    <div class="register-button" data-animate="fadeInDown" data-delay="1">
                        <a class="btn btn-transparent btn-block" href="//t.me/GeckoRelay"><i class="fa fa-key"></i>用户中心</a>
                    </div>
                    <!-- End of Register button -->
                </div>
            </div>
        </div>
    </header>
    <!-- End of Main header -->
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 960px) {
    .sticking .logo img + img {
        margin-top: 0;
        max-height: 40px;
        width: auto;
    }    
}
@media (max-width: 767.98px) {
}
@media (min-width: 768px) {
    .sticking .logo img + img {
        margin-top: 0;
        max-height: 40px;
        width: auto;
    }
}
@media (min-width: 576px) {
    .sticking .logo img + img  {
        max-height: 40px;
        width: auto;
        margin-top: 0;
    }    
}
@media (max-width: 480px) {
    .sticking .logo img + img  {
        max-height: 40px;
        width: auto;
        margin-top: 0;
    }
}
@media (min-width: 991.98px) {
    .sticking {
        padding-top: 30px !important;
        padding-bottom: 10px !important;
    }
    .sticking .logo img + img  {
        max-height: 50px;
        width: auto;
        margin-top: -5px;
    }
}
</style>
