import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/express',
      name: 'express',
      component: () => import(/* webpackChunkName: "express" */ './views/Exp.vue')
    },
    {
      path: '/relay',
      name: 'relay',
      component: () => import(/* webpackChunkName: "relay" */ './views/Relay.vue')
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import(/* webpackChunkName: "pricing" */ './views/Pricing.vue')
    },
    {
      path: '/education',
      name: 'education',
      component: () => import(/* webpackChunkName: "education" */ './views/Edu.vue')
    },
    {
      path: '/how-it-works',
      name: 'how',
      component: () => import(/* webpackChunkName: "how" */ './views/how-it-works.vue')
    },
    {
      path: '/integrations',
      name: 'integrations',
      component: () => import(/* webpackChunkName: "integrations" */ './views/Integrations.vue')
    },
//    {
//      path: '/legal',
//      name: 'legal',
//      component: () => import(/* webpackChunkName: "legal" */ './views/Legal.vue')
//    },
//      {
//        path: '/legal/tos',
//        name: 'tos',
//        component: () => import(/* webpackChunkName: "tos" */ './views/Legal_Tos.vue')
//      },
//      {
//        path: '/legal/privacy',
//        name: 'privacy',
//        component: () => import(/* webpackChunkName: "privacy" */ './views/Legal_Privacy.vue')
//      },
//      {
//        path: '/legal/policy',
//        name: 'policy',
//        component: () => import(/* webpackChunkName: "policy" */ './views/Legal_Policy.vue')
//      },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
})
